@charset "utf-8";

// Define defaults for each variable.

$base-font-family: 'Roboto', sans-serif !default;
$headings-font-family: "Poppins", serif;
$base-font-size:   15px !default;
$headings-font-size: 28px !default;

$headings-font-weight: 600 !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 25px !default;

$spacing-unit:     30px !default;

$text-color:       #444 !default;
$background-color: #FAF9F6 !default;
$background-color-dark: darken($background-color, 6%) !default;

$brand-color:      #a85749 !default;
$brand-color-light: lighten($brand-color, 45%) !default;
$brand-color-lighter: lighten($brand-color, 48%) !default;
$headings-font-color: lighten($brand-color, 30%) !default;

$grey-color:       #666 !default;
$grey-color-light: lighten($grey-color, 60%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    100% !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "sofietekent_theme/base",
  "sofietekent_theme/layout",
  "sofietekent_theme/menu",
  "sofietekent_theme/responsive",
  "sofietekent_theme/syntax-highlighting"
;

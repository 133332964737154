@media only screen and (max-width: 1200px) {


}

@media only screen and (max-width: 1050px) {
	.content .bio .float.left, .content .bio .float.right {
		width: 50%;
	}
}

@media only screen and (max-width: 1100px) {

}

@media only screen and (max-width: 840px) {
	.wrapper {
		padding: 30px;
	}
	.header {
	  padding: 0px 0px 30px 0px;
	}
	.opener {
	  top: 23px;
	  right: 18px;
	}
	h1, h3 {
		font-size: $base-font-size * 1.6;
	}
	img.type {
		height: 26px;
	}
	img.stamp {
		height: 26px;
	}
	.menu {
		padding: 50px 30px;
	}
	.menu li {
		font-size: $base-font-size * 1.2;
	}
  .menu li a {
		font-size: $base-font-size * 1.5;
	}
	.project h1 {
		margin-top: 15px;
		text-transform: lowercase;
	}
	.post.project h1 {
		margin-top: 0px;
	}
	.project p, .content .caption {
		width: 90% !important;
	}
	.content .bio .float.left {
		padding-right: 15px;
	}
	.content .bio .float.right {
		padding-left: 15px;
	}
	.content .thumb:nth-child(odd){
		padding-right: 5px;
	}
	.content .thumb:nth-child(even){
		padding-left: 5px;
	}
	.content .two {
		margin-bottom: 0px;
	}
	.content .two img {
		margin-bottom: 15px;
	}
	.content .two img:nth-child(odd){
		padding-right: 8px;
	}
	.content .two img:nth-child(even){
		padding-left: 8px;
	}
	.content .bio .images img:nth-child(odd){
		padding-right: 8px;
	}
	.content .bio .images img:nth-child(even){
		padding-left: 8px;
	}

}


@media only screen and (max-width: 600px) {

}

@media only screen and (max-width: 450px) {
	h1, h3 {
		font-size: $base-font-size * 1.4;
	}
	h2 {
	}
	.wrapper {
		padding: 20px 15px 15px 15px;
	}
	.header {
	  padding: 0px 0px 20px 0px;
	}
	img.type {
		height: 20px;
	}
	img.stamp {
		height: 20px;
		top: 3px;
	}
	.opener {
	  top: 13px;
	  right: 3px;
	}
	.project h1 {
		margin-top: 10px;
	}
	.post.project h1 {
		margin-top: -5px;
	}
	.project {
		margin-bottom: 40px;
	}
	.project div img {
		margin: 0px 0px 15px 0px;
	}
	.content .thumb {
		width: 100%;
	}
	.content .thumb:nth-child(odd){
		padding-right: 0px;
	}
	.content .thumb:nth-child(even){
		padding-left: 0px;
	}
	.content .thumb img {
		margin-bottom: 10px;
	}
	.content .two {
		margin-bottom: 0px;
	}
	.content .two img {
		width: 100%;
	}
	.content .two img:nth-child(odd){
		padding-right: 0px;
	}
	.content .two img:nth-child(even){
		padding-left: 0px;
	}
	.content .bio .images img {
		width: 100%;
	}
	.content .bio .images img:nth-child(odd){
		padding-right: 0px;
		margin-bottom: 15px;
	}
	.content .bio .images img:nth-child(even){
		padding-left: 0px;
	}
}

@media only screen and (min-width: 451px) and (max-width: 1200px){

}

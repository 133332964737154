.menu {
	display: none;
	text-align: center;
	margin: auto;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background-color: $background-color;
	z-index: 99999999;
}

.menu .list {
	position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.menu li {
  list-style: none;
  margin: 0px 15px 15px 15px;
	padding: 0px;
	font-size: 20px;
	font-weight: 300;
}

.menu li a {
	font-size: $headings-font-size;
  font-weight: 300;
  font-family: $headings-font-family;
	text-transform: lowercase;
}

.menu li a:hover {
	color: $headings-font-color;
}

.menu .current {
  color: $headings-font-color;
	font-weight: $headings-font-weight;
	font-family: $headings-font-family;
}

.menu a {
  text-decoration: none;
}

.menu li a img {
	width: auto;
	height: 24px;
	margin-top: -2px;
	line-height: $base-line-height;
}

.menu li a img:hover {
	opacity: 0.6;
}

.opener {
	display: block !important;
  position: fixed;
  top: 45px;
  right: 38px;
  z-index: 999999999999999999999999999999999;
  background-color: $background-color;
  padding: 10px;
  border-radius: 50%;
}

.header input {
  display: none;
}

.header label {
  cursor: pointer;
  display: none;
}

.header label img {
  width: 26px !important;
  height: auto;
}

#taart:checked ~ .opener .taart {
  display: none;
}

#taart ~ .opener > span:nth-of-type(1) {
  display: none;
}

#taart ~ .opener > span:nth-of-type(2) {
  display: block;
}

#taart:checked ~ .opener > span:nth-of-type(1) {
  display: block;
}

#taart:checked ~ .opener > span:nth-of-type(2) {
  display: none;
}

.sep {
	position: relative;
	width: 100%;
	clear: both;
}

.wrapper {
	width: 100%;
	padding: 50px;
	flex: 1;
}

.content {
  width: 100%;
  max-width: 1400px;
  margin: auto;
	padding-bottom: 50px;
}

.header {
	width: 100%;
  padding: 0px 0px 50px 0px;
  position: relative;
	text-align: center;
}

a.site-title {
  font-size: 0px;
}

img.type {
  height: 27px;
}

img.stamp {
	height: 27px;
	position: absolute;
	top: 0px;
	left: 0px;
}

.wrapper img {
  background: &$brand-color-light;
}

.content img {
	width: auto;
	max-width: 100%;
}

.content h1 {
}

.content h1.quote {
	font-family: "Roboto Serif", serif;
  font-weight: 700;
  color: #95a27c;
  font-style: italic;
  line-height: 28px;
}

.content h2 {
  margin-bottom: 5px;
}

.content p {
	padding: 10px 0px;
}

.content p img {
	margin-bottom: 10px;
}

.content li {
	list-style: none;
}

.content li::before {
	content: "\2022";
	font-size: 30px;
	display: inline-block;
	vertical-align: middle;
	color: $headings-font-color;
	margin-right: 5px;
}

.project {
	margin-bottom: 60px;
	width: 100%;
}

.project::after {
	content: "\2022 \2022 \2022";
	color: #95a27c;
	font-size: 28px
}

.project h1 {
	margin-top: 30px;
	text-transform: lowercase;
}

.post.project h1 {
	margin-top: 10px;
}

.project p {
	max-width: 600px;
}

.project p:last-child {
	font-weight: 300;
}

.project div img {
	margin: 0px 0px 20px 0px;
}

.content .grid {
	font-size: 0px;
}

.content .grid .thumb a:hover {
	text-decoration: none !important;
}

.content .thumb {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 50%;
	padding-bottom: 30px;
}

.content .thumb h1 {
	text-transform: lowercase;
}

.content .thumb:nth-child(odd){
	padding-right: 10px;
}

.content .thumb:nth-child(even){
	padding-left: 10px;
}

.content .thumb img {
	margin-bottom: 10px;
}

.content .thumb img:hover {
	opacity: 0.5;
	transition: opacity .5s ease-out;
    -moz-transition: opacity .3s ease-out;
    -webkit-transition: opacity .3s ease-out;
    -o-transition: opacity .3s ease-out;
}

.content .two {
	width: 100%;
	font-size: 0px;
	margin-bottom: -10px;
}

.content .two img {
	display: inline-block;
	width: 50%;
	margin-bottom: 30px;
}

.content .two img:nth-child(odd){
	padding-right: 15px;
}

.content .two img:nth-child(even){
	padding-left: 15px;
}

.content .bio {

}

.content .bio div {
	text-align: center;
	width: 100%;
	max-width: 650px;
	margin: auto;
}

.content .bio .images {
	max-width: 100%;
	font-size: 0px;
	margin: 30px 0px;
}

.content .bio .images img {
	display: inline-block;
	width: 50%;
}

.content .bio .images img:nth-child(odd){
	padding-right: 15px;
}

.content .bio .images img:nth-child(even){
	padding-left: 15px;
}

.content .bio li {
	max-width: 450px;
	margin: auto;
}

.content .bio li::before {
	display: block;
}

.content .bio .highlight {
	max-width: 900px;
	background-color: $brand-color-light;
	padding: 50px;
	margin-top: 40px;
	margin-bottom: 50px;
}

.content .highlight h1 {
	color: $brand-color;
}

.content .contact {
	margin: auto;
	text-align: center;
	width: 100%;
	max-width: 650px;
}

.postnavigation {
	width: 100%;
	padding-top: 60px;
}

.postnavigation .next, .postnavigation .previous {
	width: 50%;
}

.postnavigation .previous {
	float: left;
}

.postnavigation .next {
	text-align: right;
	float: right;
}

.footer::before {
	display: block;
	content: '';
	width: 100%;
	height: 7px;
	background-image: url('/images/footerborder.png');
	background-repeat: repeat-x;
	background-position: top;
	background-size: auto 7px;
	background-color: $background-color;
	margin-bottom: 50px;
}

.footer {
	width: 100%;
  padding-bottom: 50px;
	background-color: $brand-color-light;
	text-align: center;
}

.footer .stamp {
	position: relative;
	display: block;
	margin: auto;
	margin-bottom: 5px;
}

form {
	width: 100%;
  margin-top: 50px;
	text-align: left;
}

label, input, textarea {

}

input, textarea {
	background: #FFF;
	border: 1px solid $background-color-dark;
	padding: 10px;
	border-radius: 0px;
	font-size: $base-font-size;
	width: 100%;
	max-width: 100%;
	min-width: 100%;
	margin-bottom: 20px;
}

button {
	background: $brand-color;
	color: $background-color;
  border: none;
	padding: 20px;
	font-weight: $base-font-weight;
	text-transform: uppercase;
	font-size: $base-font-size;
	letter-spacing: 2px;
}

#mlb2-13357551 > div > div > div.ml-form-embedBody.ml-form-embedBodyDefault.row-form > form > div.ml-form-formContent > div:nth-child(1) > div {
  color: #CCC !important;
}

* {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
  color: $text-color;
}

strong {
  font-weight: 900;
}



/**
 * Basic styling
 */
 body {
   font-family: $base-font-family;
   font-weight: $base-font-weight;
   font-size: $base-font-size;
   line-height: $base-line-height;
   overflow-x: hidden;
   background: $background-color;
   min-height: 100vh;
   display: flex;
  flex-direction: column;
}

.lock-scroll {
   touch-action: none;
}

h1 {
   font-family: $headings-font-family;
   font-size: $headings-font-size;
   font-weight: $headings-font-weight;
   color: $headings-font-color;
}

h2 {
   font-weight: 500 !important;
   font-size: 20px;
}

 /*h1::after {
   content: '';
   border-top: 2px solid $headings-font-color;
   margin-top: 7px;
   margin-bottom: 30px;
   display: block;
   width: 30px;
 }*/

 h3 {
   font-weight: $headings-font-weight !important;
   font-size: $base-font-size;
 }




/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
}



/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}



/**
 * Images
 */
img {
  vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
  margin-left: 0;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}



/**
 * Headings
 */
h2, h3, h4, h5, h6 {
  font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
  color: $text-color;
  font-weight: 900;
  text-decoration: none;
  &:visited {

  }

  &:hover {
    color: $text-color;
    text-decoration: underline;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}

a.site-title {
  border: none;
}


/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;
  border-left: 4px solid $grey-color-light;
  padding-left: $spacing-unit / 2;
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}



/**
 * Code formatting
 */
pre,
code {
  @include relative-font-size(0.9375);
  border: 1px solid $grey-color-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/**
 * Wrapper
 */
.wrapper {

  @extend %clearfix;

  @include media-query($on-laptop) {

  }

}




/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}



/**
 * Tables
 */
table {
  margin-bottom: $spacing-unit;
  width: 100%;
  text-align: $table-text-align;
  color: lighten($text-color, 18%);
  border-collapse: collapse;
  border: 1px solid $grey-color-light;
  tr {
    &:nth-child(even) {
      background-color: lighten($grey-color-light, 6%);
    }
  }
  th, td {
    padding: ($spacing-unit / 3) ($spacing-unit / 2);
  }
  th {
    background-color: lighten($grey-color-light, 3%);
    border: 1px solid darken($grey-color-light, 4%);
    border-bottom-color: darken($grey-color-light, 12%);
  }
  td {
    border: 1px solid $grey-color-light;
  }
}
